import { CopyIcon } from "@radix-ui/react-icons";
import { Link } from "@remix-run/react";
import { useState } from "react";
import { Badge, Button, Flex, GridV2, Heading, Icon, IconButton, ModalV2, Text, TextInput } from "sparta";
import type { IndexLoader } from "../types";

interface IAddUsers {
  loaderData: IndexLoader;
}

export default function AddUsers({ loaderData }: IAddUsers) {
  const { share, user } = loaderData;
  const seats = user?.data?.paymentaccounts?.[0]?.subscriptionSeats;
  const remaining = user?.data?.paymentaccounts?.[0]?.users?.length;

  // state
  const [isOpen, setOpen] = useState(Boolean(share?.length));

  return (
    <ModalV2.Root open={isOpen}>
      <ModalV2.Content>
        <Flex direction="column">
          <Flex align="center" justify="center" className="modal-icon">
            <Icon name="person_add" color="white" />
          </Flex>
          <Heading as="h2">Invite Members</Heading>
          <Text color="gray">
            Send your sponsored members a sign up link to register with your account. You can also access this link and
            manage members in your <Text bold>Settings</Text> under <Text bold>Members</Text>.
          </Text>
          <Flex align="center">
            <Text style={{ paddingRight: 5 }}>Seats used:</Text>
            <Badge>
              {remaining} of {seats} seats
            </Badge>
          </Flex>
          <GridV2 columns="1fr auto" gap="2" align="end">
            <TextInput label="Share Link" aria-readonly value={share} readOnly />
            <IconButton onClick={() => navigator.clipboard.writeText(share as string)}>
              <CopyIcon />
            </IconButton>
          </GridV2>
          <ModalV2.Close>
            <GridV2 columns="1fr 1fr" gap="2">
              <Link className="nostyle" to="/settings">
                <Button variant="soft" style={{ width: "100%" }}>
                  Go to settings
                </Button>
              </Link>
              <Button onClick={() => setOpen(false)}>Done</Button>
            </GridV2>
          </ModalV2.Close>
        </Flex>
      </ModalV2.Content>
    </ModalV2.Root>
  );
}
